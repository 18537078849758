import React, { useEffect } from 'react';
import './MenuItem.scss';
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router';




const MenuItem = ({ children, link, alternate }) => {

  const navigate = useNavigate();

  const current = useLocation().pathname == link;
 
  return(
    <div className={`menu-item ${current && 'current'} ${alternate && 'alternate'}`} onClick={() => navigate(link)}>
      {children}
    </div>
  )
}

export default MenuItem;