import React from 'react';
import Navigation from '../Navigation/Navigation';
import "./Page.scss";

const Page = ({ children, background}) => {

  return(
    <div className='page'>
      
      {children}  
    </div>
  )
}

export default Page;