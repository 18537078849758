import React from 'react';
import "./ConfirmationPanel.scss"

const ConfirmationPanel = ({ message, onConfirm, onCancel }) => {
  return (
    <div className='confirmation-panel'>
      <p>{message}</p>
      <button onClick={onCancel}>Cancel</button>
      <button onClick={onConfirm}>Delete</button>
    </div>
  )
}

export default ConfirmationPanel;