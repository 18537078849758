import React from "react";
import "./Bio.scss";

const Bio = ({ children, imageSource }) => {
  return (
    <div className='bio'>
      <div>
        <img src={imageSource} />
      </div>
      
      <div>
        {children}
      </div>
    </div>
  )
}

export default Bio;