import Market from "./Market";

export const getContent = (contentType, creator, limit, page) => {
  return Market.get(`${contentType}s/mine?limit=${limit}&offset=${page*limit}&ordering=-update_date`).then(response => response.data);
}

export const getItem = (contentType, itemId, json) => {
  return Market.get(`${contentType}s/${itemId}/${json ? "get_json" : ""}`).then(response => response.data);
}

export const updateItem = (contentType, itemId, name, json, description) => {
  let formData = new FormData();
  name && formData.append("name", name)
  json && formData.append("json", json)
  description && formData.append("description", description);
  return Market.patch(`${contentType}s/${itemId}/`, formData);
}

export const deleteItem = (contentType, itemId) => {
  return Market.delete(`${contentType}s/${itemId}/`);
}
