import React, { useEffect, useState } from 'react';
import Page from '../components/Page/Page';
import SplashSection from '../components/Section/SplashSection';
import { getContent, getItem, updateItem } from '../services/Content';
import { useParams } from 'react-router';
import { render } from '@testing-library/react';
import ProfilePage from '../components/Page/ProfilePage';
import Section from '../components/Section/Section';
import EditForm from '../components/EditForm/EditForm';
import Thumbnail from '../components/Thumbnail/Thumbnail';


export const SystemProfile = () => {
  const systemId = useParams().systemId;
  const [system, setSystem] = useState(null);
  const [systemData, setSystemData] = useState(null);
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  
  useEffect(() => {
   getSystem();
    getSystemData();
    
  }, [])

  useEffect(() => {
    systemData && setDescription(systemData.description);
  }, [systemData])


  const getSystem = () => {
    getItem("system", systemId, false).then(data => {
      console.log("SYSTEM", data);
      setSystem(data);
      setName(data.name);
      data.description && setDescription(data.description);
    })
  }


  const getSystemData = () => {
    getItem("system", systemId, true).then(data => {
      console.log("SYSTEM DATA", data);
      setSystemData(data);
    })
  }

  const toggleEditing = () => {
    setEditing(!editing);
  }

  const onSubmitEditForm = (e) => {
    console.log("submitting");
    e.preventDefault();
    let newSystem = system;
    newSystem.description = description;
    newSystem.name = name;
    updateItem("system", system.id, name, JSON.stringify(systemData), description).then(response => {
      getSystemData();
    });

  }

  return(
    <ProfilePage>
      <Section>        
        {system && 
          <div>
            <h2>{system.name}</h2>
            <Thumbnail source={`https://svs-assets.s3.us-west-2.amazonaws.com/thumbnails/${system.id}.png`} />
            <p>{system.description}</p>

          </div>
        
        }
        {system && editing && <EditForm
          onSubmitEditForm={onSubmitEditForm}
          editableFields={[
            {
              label: "Name",
              setValue: setName,
              value: name
            },
            {
              label: "Description",
              setValue: setDescription,
              value: description
            }
          ]}
        />}
        {system && !editing && <button onClick={toggleEditing}>Edit system data</button>}

      </Section>
      
    </ProfilePage>
    
  )
}