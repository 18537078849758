import React, { useEffect, useState } from 'react';
import Page from '../components/Page/Page';
import SplashSection from '../components/Section/SplashSection';
import { getContent, getItem, updateItem } from '../services/Content';
import { useParams } from 'react-router';
import { render } from '@testing-library/react';
import ProfilePage from '../components/Page/ProfilePage';
import Section from '../components/Section/Section';
import EditForm from '../components/EditForm/EditForm';
import Thumbnail from '../components/Thumbnail/Thumbnail';


export const EnvironmentProfile = () => {
  const environmentId = useParams().environmentId;
  const [environment, setEnvironment] = useState(null);
  const [environmentData, setEnvironmentData] = useState(null);
  const [editing, setEditing] = useState(false);
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  
  useEffect(() => {
    getEnvironment();
    getEnvironmentData();
    
  }, [])

  useEffect(() => {
    environmentData && setDescription(environmentData.description);
    environment && setName(environment.name);
   
  }, [environmentData, environment])

  const getEnvironment = () => {
    getItem("environment", environmentId, false).then(data => {
      console.log("ENVIRONMENT", data);
      setEnvironment(data);
    })
  }

  const getEnvironmentData = () => {
    getItem("environment", environmentId, true).then(data => {
      console.log("ENVIRONMENT DATA", data);
      setEnvironmentData(data);
    })
  }

  const toggleEditing = () => {
    setEditing(!editing);
  }

  const onSubmitEditForm = (e) => {
    console.log("submitting", name);
    e.preventDefault();
    let newEnvironmentData = environmentData;
    newEnvironmentData.description = description;
    updateItem("environment", environment.id, name, JSON.stringify(newEnvironmentData)).then(response => {
      getEnvironment();
      getEnvironmentData();
      setEditing(false);
    });

  }
  
  return(
    <ProfilePage>
       <Section>
        {environment && environmentData && 
          <div className='element-card'>
            <h2>{environment.name}</h2>
            <Thumbnail source={`https://svs-assets.s3.us-west-2.amazonaws.com/thumbnails/${environment.id}.png`} />
            <h4>{environmentData.displayName}</h4>
            <p>{environmentData.description}</p>

          </div>
        
        }
        {environment && editing && <EditForm
          onSubmitEditForm={onSubmitEditForm}
          editableFields={[
            {
              label: "Name",
              setValue: setName,
              value: name
            },
            {
              label: "Description",
              setValue: setDescription,
              value: description
            }
          ]}
        />}
        {environment && !editing && <button onClick={toggleEditing}>Edit environment data</button>}

      </Section>
      
    </ProfilePage>
    
  )
}