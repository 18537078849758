import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import Page from '../components/Page/Page';
import Section from '../components/Section/Section';
import ContentGrid from '../components/compositions/ContentGrid';



export const Home = () => {
  const navigate = useNavigate();


  return(
    <Page>
      <Section>
        <h1>Welcome</h1>
        <p>This is where you can find and edit all your 10k systems, environments, and elements.</p>
        <p>If you are participating in the bootcamp, <Link to="../bootcamp">go here for information.</Link></p>
      </Section>
      <Section>
        <h2>Systems</h2>
        <ContentGrid pageSize={3} autoLoad={false} contentType="system" />
        <button onClick={() => navigate('../systems')}>See all systems →</button>
      </Section>
      <Section>
        <h2>Environments</h2>
        <ContentGrid pageSize={3} autoLoad={false} contentType="environment" />
        <button onClick={() => navigate('../environments')}>See all environments →</button>
      </Section>
      <Section>
        
        <h2>Elements</h2>
        <ContentGrid pageSize={3} autoLoad={false} contentType="element"/>
        <br />
        <button onClick={() => navigate('../elements')}>See all elements →</button>
      </Section>
       


      
    </Page>
    
  )
}