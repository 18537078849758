import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Navigate, Outlet } from 'react-router';
import './App.scss';
import { Layout } from './components/Layout';
import { NoPage } from './components/NoPage';
import { Home } from './pages/Home';
import { Element, ElementProfile } from './pages/ElementProfile';
import Login from './pages/Login';
import cookie from 'react-cookies';
import Elements from './pages/Elements';
import Bootcamp from './pages/Bootcamp';
import Systems from './pages/Systems';
import Environments from './pages/Environments';
import { EnvironmentProfile } from './pages/EnvironmentProfile';
import { SystemProfile } from './pages/SystemProfile';

const ProtectedRoute = ({ user, redirectPath = '/login' }) => {
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};

function App() {
  const id = cookie.load('id');
  const user = id != undefined;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route element={<Layout />}>
          
          <Route path="/" element={<ProtectedRoute user={user} />}>
            <Route path="/" element={<Home />} />
            <Route path="/elements" element={<Elements />} />
            <Route path="/elements/:elementId" exact element={<ElementProfile />} />
            <Route path="/environments" element={<Environments />} />
            <Route path="/environments/:environmentId" exact element={<EnvironmentProfile />} />
            <Route path="/bootcamp" element={<Bootcamp />} />
            <Route path="/systems" element={<Systems />} /> 
            <Route path="/systems/:systemId" exact element={<SystemProfile />} />
            
          </Route>

          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;



