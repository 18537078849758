import React, { useEffect } from "react";
import "./EditForm.scss";

const EditForm = ({ onSubmitEditForm, editableFields }) => {
 
  return(
    <div className='edit-form'>
      <form onSubmit={onSubmitEditForm}>
        {editableFields.map((field, i) => (
          <label>
            {field.label}
            <textarea  onChange={(e) => field.setValue(e.target.value)} value={field.value} />
          </label>
        ))}
        <button type="submit" onClick={onSubmitEditForm}>Submit</button>
      </form>
    </div>
  )
}

export default EditForm;