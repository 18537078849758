import React from 'react';
import Page from './Page/Page';
import SplashSection from './Section/SplashSection';

export const NoPage = () => {
  return(
    <Page>
      <br /> <br />
      <h2>Page not found</h2>
    </Page>

  )
}