import React from 'react';
import Page from '../components/Page/Page';
import Section from '../components/Section/Section';
import SarahKielson from '../assets/bios/sarah_kielson.png';
import JohnPollock from '../assets/bios/john_pollock.jpg';
import Vladimir from '../assets/bios/vladimir_yarov-yarovoy.jpg';
import Bio from '../components/Bio/Bio';
import Blair from '../assets/bios/blair_lyons.jpg';
import Markita from '../assets/bios/markita_landry.jpg';
import Ross from '../assets/bios/ross_wilson.jpg';

const Bootcamp = ({}) => {
  return(
    <Page>
      <Section>
        <h1>Bootcamp</h1>
        <br />
        <h2>Overview</h2>
        <p>Dynamoid’s inaugural bootcamp is designed for grad students and postdocs to explore cutting-edge 3D VR techniques to visualize their datasets, including the 10k platform, while keeping in mind best practices for science communications.</p>
        <p>During the bootcamp, you will be working with Dynamoid staff to determine the possibilities for visualizing your data using 10k’s current capabilities as well as help identify features that can be added in the short and long term to support your (and others’)  visualization needs. We will also work together to review your current data workflow, and identify any other tools (VR and otherwise) that may help you more effectively visualize and communicate your data.</p>
      </Section>
      <Section>
        <h2>Expert Panelists</h2>
        <p>Over the course of the (approximately) two week period, you will receive iterative feedback from our assembled Expert Panel, who have a variety of backgrounds in scientific and communication/education fields.</p>

        {/* <Bio imageSource={Vladimir}>
          <h4>Vladimir Yarov-Yarovoy</h4>
          <p>A biochemistry professor at UC Davis, Vladimir Yarov-Yarovoy's research interests and expertise encompass neuroscience, protein structure, and computational biology. The main focus of his research group is on computational design of subtype-specific ion channel modulators, structure and function studies of voltage-gated ion channels, and development of computational methods for membrane protein structure prediction and design. Recent advances in computational protein design, determination of high-resolution ion channel structures and discoveries of peptide toxins that target them with high affinity, set a stage for computational design of novel ion channel modulators. He currently has a NIH HEAL Initiative grant to design and optimize peptides targeting the voltage-gated sodium channels involved in pain signaling.</p>
        </Bio>
       
        <Bio imageSource={SarahKielson}>
          <h4>Sarah Kielson</h4>
          <p>Sarah Keilson brought a background in medical research as well as graduate education in both medical science and technical arts to the Medical Communications industry. She's spent the last decade visualizing science through 3D animation, extended reality, information design, and more. Sarah has been responsible for Creative Direction supporting hundreds of pharmaceutical products in numerous disease areas, specializing in oncology and immunology. She serves as a professional mentor, team-builder, challenger of what's possible, and driver of business development. A year ago, she opened her own boutique agency, and is excited about making even more of an impact doing what she's most passionate about: equipping healthcare professionals with the knowledge they need to make the best decisions for their patients.</p> 
        </Bio>
       
       <Bio imageSource={JohnPollock}>
          <h4>John A. Pollock</h4>
          <p>John Pollock is a Full Professor of Biological Science at Duquesne University Bayer School of Natural and Environmental Sciences, where he researches and teaches molecular neurobiology. He is Principal Investigator for The Partnership in Education, a non-profit multidisciplinary health literacy and informal science education project at Duquesne University funded primarily through a Science Education Partnership Award(SEPA) from the National Institute of General Medical Sciences, a component of the National Institutes of Health.</p>
          <p>Pollock holds the additional title of co-director for the Chronic Pain Research Consortium at Duquesne University. He serves as a faculty affiliate at the BrainHub at Carnegie Mellon University and separately at the McGowan Institute for Regenerative Medicine, University of Pittsburgh/UPMC.</p>
       </Bio> */}

        <Bio imageSource={Blair}>
          <h4>Blair Lyons</h4>
          <p>Blair Lyons joined the Allen Institute for Cell Science's Animated Cell team in 2017 to help design and build a framework for visualization of molecular simulations. She also creates virtual reality apps to present cell science data. </p>
          <p>Blair earned her BS in Biochemistry from Virginia Tech and MS in Biomedical Visualization from the University of Illinois at Chicago. Prior to joining the Allen Institute, she was a partner at Dynamoid, and helped to design the data tools and infrastructure that form the foundation of the 10k platform. Blair's goal is to increase access to biology through visualization and interactivity, especially finding more intuitive ways to teach quantitative reasoning and experimental design.</p>    
        </Bio>

        <Bio imageSource={Markita}>
          <h4>Markita Landry</h4>
          <p>Markita Landry is an assistant professor in the department of Chemical and Biomolecular Engineering at the University of California, Berkeley. Her current research centers on the development of synthetic nanoparticle-polymer conjugates
            for imaging neuromodulation in the brain, and for the delivery of genetic materials into plants. The Landry lab exploits the highly tunable chemical and physical properties of nanomaterials for the creation of bio-mimetic structures, molecular imaging, and plant genome editing. She is also on the scientific advisory board of Terramera and on the scientific advisory board of Chi-Botanic. She is a recent recipient of over 20 early career awards, including awards from the Brain and
            Behavior Research Foundation, the Burroughs Wellcome Fund, the DARPA Young Investigator program, the Beckman Young Investigator program, the Howard Hughes Medical Institute, the NSF CAREER award, the Vilcek Foundation, is a Sloan Research Fellow, an FFAR New Innovator, and is a Chan-Zuckerberg Biohub Investigator.</p>
        </Bio>

        <Bio imageSource={Ross}>
          <h4>Ross Wilson</h4>
          <p>Ross Wilson is an assistant professor in the Molecular & Cell Biology Dept. at UC Berkeley. Ross' scientific endeavors have been unified by his interest in the reactions between RNA and proteins, though the projects he's focused on over the years have gradually shifted away from fundamental research, towards research with immediate medical impact. The Wilson Lab is dedicated to the development of delivery technology for therapeutic genome editing, an effort that is poised to enable countless new medical applications.</p>
        </Bio>

        
          

      </Section>
      <Section>
        <h2>Syllabus</h2>
        <h3>Week 1</h3>
        <h4>Activities</h4>
        <ul>
          <li>Training to use the 10k platform to create experiences</li>
          <li>Review of other VR creation applications</li>
          <li>Initial import of your data</li>
          <li>Initial draft of content for experience</li>
        </ul>
        
        <h4>Events</h4>
        <ul>
          <li>7/11 Group session: Kickoff Day</li>
          <li>TBD One-on-one meeting: Dataset and workflows review</li>
          <li>TBD Group session: File formats for storing visualization data</li>
          <li>TBD One-on-one meeting: Feedback meeting with panelist</li>
        </ul>


        <h3>Week 2</h3>
        <h4>Activities</h4>
        <ul>
          <li>Final draft of content for experience, including descriptions</li>
          <li>Final import of data</li>
          <li>Assemble final experience</li>
        </ul>
        
        <h4>Events</h4>
        <ul>
          <li>TBD Group session: The future of data visualization in VR</li>
          <li>TBD One-on-one meeting: Feedback meeting with panelist</li>
          <li>7/21 Group session: Demo Day</li>
          
        </ul>



      </Section>

      <Section>
        <h2>Logistics</h2>
        <h3>Onsite activities</h3>
        <p>You are welcome to come work at the Dynamoid offices whenever you would like over the course of the bootcamp, but you can be remote as much as you prefer: there will be Zoom meeting links for every session. On-site attendance is encouraged for Kickoff Day if you have little prior experience in VR and/or PC gaming as the training that is necessary will be more difficult remotely.</p>
        <h3>Dynamoid offices</h3>
        <p>We are located at 1633 Broadway, Suite C, Oakland CA. The 19th st BART station is a half a block or so away (depending on where you exit). <p>Office hours are roughly 10 am - 4 pm.</p> <p>Ring the buzzer to be let in or text Laura Lynn Gonzalez at 412-726-4274 if you have any issues.</p></p>
        <p>The WIFI network is <b>Dynanet</b> and the password is <b>1633Broadway</b></p>
      </Section>
    </Page>
  )
}

export default Bootcamp;

