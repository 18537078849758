import { render } from '@testing-library/react';
import React from 'react';
import { deleteItem } from '../../services/Content';
import Card from './Card';


const ContentCard = ({ item, itemType }) => {
  

  return(
    <Card 
      link={`../${itemType}s/${item.id}`} 
      title={item.name} 
      thumbnail={`https://svs-assets.s3.us-west-2.amazonaws.com/thumbnails/${item.id}.png`}
      onDelete={() => deleteItem(itemType, item.id) }
    >
      {item.pending && <h3>PENDING</h3>}
      <p>{item.description}</p>
    </Card>
  )
}

export default ContentCard;