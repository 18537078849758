import React, { useState } from "react";
import Placeholder from "../../assets/element_thumbnail.png"

const Thumbnail = ({ source }) => {
  const [imageSource, setImageSource] = useState(source);

  const handleError = () => setImageSource(Placeholder);

  return <img src={imageSource} onError={handleError} />
}

export default Thumbnail;