import axios from 'axios';
import cookie from 'react-cookies';


const headers = cookie.load('token') ? {
	'Accept': 'application/json; version=1.0',
	'Content-Type': 'application/json',
	'Authorization': 'Token ' + cookie.load('token')
} :
	{
		'Accept': 'application/json; version=1.0',
		'Content-Type': 'application/json'
	}

export default axios.create({
	baseURL: process.env.REACT_APP_PEOPLE,
	responseType: 'json',
	headers: headers,
	withCredentials: false
})
