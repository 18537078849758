import React, { useEffect, useState } from 'react';
import Page from '../components/Page/Page';
import SplashSection from '../components/Section/SplashSection';
import { getContent, getItem, updateItem } from '../services/Content';
import { useParams } from 'react-router';
import { render } from '@testing-library/react';
import ProfilePage from '../components/Page/ProfilePage';
import Section from '../components/Section/Section';
import EditForm from '../components/EditForm/EditForm';
import Thumbnail from '../components/Thumbnail/Thumbnail';


export const ElementProfile = () => {
  const elementId = useParams().elementId;
  const [element, setElement] = useState(null);
  const [elementData, setElementData] = useState(null);
  const [editing, setEditing] = useState(false);
  const [description, setDescription] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    getElement();
    getElementData();

  }, [])

  useEffect(() => {
    if (elementData && element) {
      setDescription(elementData.labelData.description);
      setDisplayName(elementData.labelData.displayName)
      setName(element.name);
    }
  }, [elementData, element])

  const getElement = () => {
    getItem("element", elementId, false).then(data => {
      console.log("ELEMENT", data);
      setElement(data);
    })

  }

  const getElementData = () => {
    getItem("element", elementId, true).then(data => {
      console.log("ELEMENT DATA", data);
      setElementData(data);
    })
  }

  const toggleEditing = () => {
    setEditing(!editing);
  }

  const onSubmitEditForm = (e) => {
    console.log("submitting");
    e.preventDefault();
    let newElementData = elementData;
    newElementData.labelData.description = description;
    updateItem("element", element.id, name.replaceAll(" ", "_").replaceAll(",", ""), JSON.stringify(newElementData)).then(response => {
      getElement();
      getElementData();
      setEditing(false);
    });

  }


  return (
    <ProfilePage>
    <Section>
      {element && elementData &&
        <div>
          <h2>{element.name}</h2>
          {element.pending && <h4>PENDING</h4>}
          <Thumbnail source={`https://svs-assets.s3.us-west-2.amazonaws.com/thumbnails/${element.id}.png`} />
          <h4>{elementData.labelData.displayName}</h4>
          <p>{elementData.labelData.description}</p>

        </div>

      }
      {element && editing && <EditForm 
      onSubmitEditForm={onSubmitEditForm} 
      editableFields={[
        {
          label: "Name",
          setValue: setName,
          value: name
        },
        {
          label: "Display name",
          setValue: setDisplayName,
          value: displayName
        },
        {
          label: "Description",
          setValue: setDescription,
          value: description
        }
      ]}
      />}
      {element && !editing && <button onClick={toggleEditing}>Edit element data</button>}
      </Section>
    </ProfilePage>



  )
}