import cookie from 'react-cookies';
import People from './People';




export const login = (email, password, onLoginSuccess, onLoginFail) => {
	People.post('login/', JSON.stringify({
		username: email,
		password: password,
		account_type:"Email"
		
	}))
		.then(response => {
			console.log(response.data)

			const domain = window.location.origin.includes("localhost") ? "" : "karunalabs.com";
			console.log(window.location.origin);

			
			if (response.data.token && response.data.token != "") {

				console.log("LOGIN WAS SUCCESSFUL, Saving cookie", response.data.token);
				console.log(window.location.protocol);
				const isSecure = window.location.protocol == "https:";

				!isSecure && console.log("IS NOT SECURE");
			
				cookie.save('token', response.data.token, { maxAge: 86400,  path: "/" });

				cookie.save('user', response.data, { maxAge: 86400, path: "/" });
	
				cookie.save('id', response.data.id, { maxAge: 86400, path: "/"});
				onLoginSuccess && onLoginSuccess(response.data)
				
				
			}
			else {
				onLoginFail && onLoginFail("Username or password is incorrect");
			}

		})
		.catch(error => {
			console.error("THERE WAS AN ERROR", error.response); 
			onLoginFail && onLoginFail(error.response && error.response.data);   

		});

}




export const logout = (onLogout) => {
	const domain = window.location.origin.includes("localhost") ? "" : "karunalabs.com";
	console.log("DOMAIN", domain);

	// People.get('logout').then(response => {
	// 	console.log(response)
		
		
		
	//  }).catch(error => {
		 
		
	//  });

	 console.log("removing cookies");
	cookie.remove('id', { path: "/" });
	// cookie.remove('role', { domain: domain })
	// cookie.remove('user', { domain: domain })
	onLogout && onLogout();

	
	
	
	
}





