import React, { useEffect, useState } from 'react';
import Grid from '../layouts/Grid/Grid';
import cookie from 'react-cookies';
import { getContent } from '../../services/Content';
import ContentCard from '../Cards/ContentCard';

const ContentGrid = ({ pageSize, autoLoad, contentType }) => {
  const [items, setItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(0);
  const id = cookie.load('id');
  const user = cookie.load('user');

  

  useEffect(() => {
    console.log(user);

    if (totalItems >= page * pageSize) {
      getContent(contentType, id, pageSize, page).then(data => {

        if(data.results){
          console.log(`Gotten ${contentType}s:`, data);
          setTotalItems(data.count);
          setItems([...items, ...data.results]);
        }
        else{
          setItems(data);
        }
        

      })
    }



  }, [page])

  useEffect(() => {
    console.log(items);

    if(autoLoad){
      const scrolling_function = () => {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 10) {
          console.log("fetching more.........")
          setPage(page + 1);
          window.removeEventListener('scroll', scrolling_function)
        }
      }
      window.addEventListener('scroll', scrolling_function);
    }
    
  }, [items])

  const contentCard = () => {

  }

  return(
    <Grid>
      {items && items.length == 0 && <p>No {contentType}s yet! Use the 10k application to create a new {contentType}</p>}
      {items && items.map((item) =>
        <ContentCard item={item} key={item.id} itemType={contentType} />
      )}
      <div />
    </Grid>
  )
}


export default ContentGrid;