import React from 'react';
import Page from '../components/Page/Page';
import Section from '../components/Section/Section';
import ContentGrid from '../components/compositions/ContentGrid';



const Elements = () => {

  return(
    <Page>
      <Section>
        <h1>Elements</h1>
        <ContentGrid pageSize={30} autoLoad={true} contentType="element"/>
      </Section>
       


      
    </Page>
    
  )
}

export default Elements;