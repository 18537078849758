import React from 'react';
import ContentGrid from '../components/compositions/ContentGrid';
import Page from '../components/Page/Page';
import Section from '../components/Section/Section';

const Environments = () => {
  return(
    <Page title="Environments">
      <Section>
        <h1>Environments</h1>
        <ContentGrid pageSize={30} autoLoad={true} contentType="environment" />
      </Section>
    </Page>
  )
}

export default Environments;