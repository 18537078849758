import React from 'react';

const Section = ({ children, background, classes }) => {

  return(
    <div className={`section ${classes}`} style={{ "background-image": `url(${background})` }}>
      {children}
    </div>
  )
}

export default Section;
