import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import ConfirmationPanel from '../ConfirmationPanel/ConfirmationPanel';
import Thumbnail from '../Thumbnail/Thumbnail';
import "./Card.scss"


const Card = ({ children, link, history, title, thumbnail, onDelete }) => {
  const navigate = useNavigate();
  const [deleting, setDeleting] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const navigateToLink = () => {
    link && navigate(link);
  }

  const deleteItem = () => {
    onDelete();
    setDeleted(true);
  }

  if(deleted){
    return "";
  }

  

  return(

    <div className='card-layout'>
      <div className={`card ${link ? "link" : ""}`} onClick={navigateToLink}>
        <div className='card-content'>
          <h4>{title}</h4>
          <Thumbnail source={thumbnail} />
          {children}
        </div>
        
      </div>
      <div className='static-buttons'>
        {deleting ? <ConfirmationPanel message="Really delete?" onConfirm={deleteItem} onCancel={()  => setDeleting(false)}/> : <button onClick={() => setDeleting(true)}>Delete</button>}
      </div>
    </div>
    
    
  )
}

export default Card;

