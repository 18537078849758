import React from 'react';
import { Outlet } from 'react-router';
import Header from './Header/Header';
import Navigation from './Navigation/Navigation';

export const Layout = () => {
  return(
    <>
      <Outlet />
      <Navigation />
      {/* <Header /> */}
      
      
    </>
  )
}