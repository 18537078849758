import React, { useState } from 'react';
import Input from '../components/Input/Input';
import Page from '../components/Page/Page';
import { login } from '../services/Accounts';
import { Link } from 'react-router-dom';
import FormErrors from '../components/FormErrors';
import Section from '../components/Section/Section';
import { useNavigate } from 'react-router';
import Panel from '../components/Panel/Panel';

const Login = ({ history, location, ...rest }) => {

	const [username, setUsername] = useState(null);
	const [password, setPassword] = useState(null);
	const [loginText, setLoginText] = useState("Sign in");
	const [errors, setErrors] = useState(null);
	const navigate = useNavigate();



	const usernameChanged = (value) => {
		setLoginText("Sign in");
		setErrors(null);
		setUsername(value);
	}

	const passwordChanged = (value) => {
		setLoginText("Sign in");
		setErrors(null);
		setPassword(value);
	}


	const onLoginSuccess = (userData) => {
		console.log("ON LOGIN SUCCESS", userData)
		// if (location && location.state && location.state.from) {
		// 	navigate(location.state.from);
		// }

		// else {
		window.location = '/';
		// }
	}

	const onLoginFail = (message) => {
		setLoginText("Try again");
		setErrors("Login failed", message);
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		setLoginText("Signing in");
		login(username, password, onLoginSuccess, onLoginFail);
	}

	const steamLogin = () => {
		window.location.href = `https://people.dynamoid.io/login/steam?next=/api/redirect/${window.location.host}`
	}

	return (
		// <Page title="Welcome to 10k">
			<div className="login">
				<Section classes="center">
				<br />	<br />	<br />	<br />	<br />	<br />	<br />
				

				</Section>
				<Section classes="center">
					{/* <FormErrors errors={errors} /> */}
					<Panel>
					<center><h1>Welcome</h1></center>
						<form >
							<Input name="Email" value={username} type="text" onChange={usernameChanged} noLabel required validations={[{ type: "email" }]} formErrors={errors} />
							<Input name="Password" value={password} type="password" onChange={passwordChanged} noLabel required formErrors={errors} />
							<button className={errors ? "error" : ""} onClick={handleSubmit}>{loginText}</button>
						</form>
					</Panel>
					
					<br></br>
				{/* <center><Link to="/password/reset" >Forgot password?</Link></center> */}
				</Section>
				{/* <Section>
					<button onClick={steamLogin}>Login with Steam</button>
				</Section> */}
			
			</div>
		// </Page>

	);


}

export default Login;
