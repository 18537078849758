import React from 'react';
import MenuItem from '../MenuItem/MenuItem';
import './Navigation.scss'
import { useNavigate } from 'react-router';
import { logout } from '../../services/Accounts';

import cookie from 'react-cookies';
import logo from '../../assets/10k.png';

const Navigation = ({ }) => {
  const user = cookie.load('user');
  const navigate = useNavigate();

  return(
    <div className='navigation'>
      <img src={logo} width="100%" />

      
      <MenuItem link="/">Home</MenuItem>
      <MenuItem link="/elements">Elements</MenuItem>
      <MenuItem link="/environments">Environments</MenuItem>
      <MenuItem link="/systems">Systems</MenuItem>
      
      

      <div className='navigation-footer'>
        <MenuItem alternate link="/bootcamp">Bootcamp</MenuItem>

        <div className='profile'>
          <p>{user && user.username}</p>
          <button onClick={() => logout(() => navigate('../login'))}>Sign out</button>
        </div>
       
      </div>
    </div>
  )
}

export default Navigation;