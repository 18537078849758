import React from 'react';
import ContentGrid from '../components/compositions/ContentGrid';
import Page from '../components/Page/Page';
import Section from '../components/Section/Section';

const Systems = () => {
  return(
    <Page title="Systems">
      <Section>
        <h1>Systems</h1>
        <ContentGrid pageSize={30} autoLoad={true} contentType="system" />
      </Section>
    </Page>
  )
}

export default Systems;