import React from 'react';
import Page from './Page';

const ProfilePage = ({ children }) => {
  return(
    <Page>
      <div className='profile-page'>
        {children}
      </div>
    </Page>
    
  )
}

export default ProfilePage;